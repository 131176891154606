import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import React from "react"

const InstaGrid = () => {
  const data = useStaticQuery(graphql`
  {
    homeInstaYaml {
      insta_imgs {
        link
        alt
        src {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, aspectRatio: 0.8)
          }
        }
      }
    }
  }
  `);

    const insta_imgs = data.homeInstaYaml.insta_imgs.slice(0, 16);

    const renderImg = (x) => {
        const img_src = getImage(x.src);

        return img_src ? <div className="w-1/2 md:w-1/4 pb-3 pr-3 lg:w-auto lg:p-0" key={x.src.id}>
          <a href={x.link} target="_blank" rel="nofollow noopener noreferrer" className="">
              <GatsbyImage image={img_src} alt={x.alt} className="block pixelate relative transition duration-200 ease-in-out lg:transform hover:scale-110 hover:z-10 rounded-md border-4 border-white border-opacity-60 hover:border-opacity-100 hover:border-purple-600" />
          </a>
        </div> : null;
    };

  return (
    <div className="instagram max-w-1920 mx-auto pl-3 text-white flex flex-wrap lg:flex-none lg:grid lg:grid-cols-6 lg:grid-rows-2 lg:gap-4 lg:px-4 lg:py-4 ">
      {insta_imgs.slice(0, 2).map((x) => {
          return renderImg(x);
      })}
        
      <div className="order-first w-full pt-14 pb-10 flex flex-col justify-center items-center lg:order-none lg:col-span-2 lg:p-0   ">
          <svg className="w-12 sm:w-16 lg:w-12 mb-6 lg:mb-10" aria-label="Instagram icon" focusable="false" data-prefix="fab" data-icon="instagram" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg>
          <a className="text-5xl sm:text-6xl md:text-7xl lg:text-6xl xl:text-7xl font-serif" href="https://www.instagram.com/talsguide/" target="_blank" rel="noopener noreferrer">
              @talsguide
          </a>
      </div>

      {insta_imgs.slice(2).map((x) => {
          return renderImg(x);
      })}   
    </div>
  )
};

export default InstaGrid
