import InstaGrid from "../components/InstaGrid"
import Layout from "../components/Layout"
import {Link} from "gatsby"
import React from "react"
import Seo from "../components/seo"
import Slider from "../components/Slider"
import { StaticImage } from "gatsby-plugin-image"

const IndexPage = () => (
  <Layout headerOverlap>
    <Seo />
    
    <div className="flex flex-col lg:flex-row max-w-1920 mx-auto">
      <Slider className="home-slider">
        <StaticImage className="h-full" src="../assets/images/home/tc530-abb-peach.jpg" alt="Angled view, looking from the bridge up to the headstock of an Azure Blue Burst Talman TC530" placeholder="tracedSVG" width={1680} loading="eager" quality={75} />
        <StaticImage className="h-full pixelate" src="../assets/images/home/3-talmans.jpg" alt="3 Talman guitars on a guitar stand" placeholder="blurred" width={1680} quality={75}  />
        <StaticImage className="h-full pixelate" src="../assets/images/home/TC620-OM-sun.jpg" alt="TC620-OM Talman at twilight" placeholder="blurred" width={1680} quality={75}  />
        <StaticImage className="h-full pixelate" src="../assets/images/home/silver-gold.jpg" alt="Sparkle Talman bass and guitar in moody lighting" placeholder="blurred" width={1680} quality={75}  />
      </Slider>

      <div className="flex flex-col lg:w-1/2 lg:h-screen ">
        
        <div className="relative p-7 sm:p-12 md:p-20 lg:p-24 lg:min-h-1/2">
          <div className="relative z-10 flex flex-col justify-center h-full w-full text-center md:text-left">
            <h1 className="text-5xl sm:text-6xl md:text-7xl font-bold font-serif">Welcome</h1>
            <p className="text-2xl md:text-3xl mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-pink-500">to the unofficial guide to everything Ibanez&nbsp;Talman</p>
            <p className="text-lg md:text-xl lg:text-2xl mb-4">I'm launching this site <span className="text-2xl" aria-label="party face" role="img">🥳</span> with the <Link to="/tc530">TC530</Link>, <Link to="/tv650">TV650</Link>, and <Link to="/tv750">TV750</Link> model pages! Those were the original Talman lineup in 1994! I'm also launching the <Link to="/store">Tal's Guide store</Link>! There's <em>a lot</em> more to come.</p>
          </div>

          <div className="absolute left-0 top-0 w-full h-full z-0 bg-gradient-to-t from-purple-900 opacity-40" />
        </div>

        

        <div className="flex lg:h-1/2 overflow-hidden">
          <div className="w-1/2 relative">
            <div className="lg:absolute w-full h-full"><Link to="/tc530" className="img-link h-full"><StaticImage className="h-full" src="../assets/images/home/TC530-IV-headstock.jpg" alt="Talman TC530 headstock" placeholder="tracedSVG" width={500} loading="eager" quality={75} /></Link></div>
          </div>
          <div className="w-1/2 relative">
            <div className="lg:absolute w-full h-full"><Link to="/tv750" className="img-link h-full"><StaticImage className="h-full" src="../assets/images/home/tv750-mood.jpg" alt="TV750 resting on wood table" placeholder="tracedSVG" width={500} loading="eager" quality={75} /></Link></div>
          </div>
        </div>
      </div>
    </div>
    
    <div className="relative w-full overflow-hidden max-w-1920 mx-auto">
      <Link to="/store" className="relative z-20 block w-full h-full px-5 py-10 md:p-20 no-underline border-none">
        <h2 className=" text-shadow text-2xl md:text-4xl font-serif font-bold text-white">Tal's Guide Store</h2>
        <h3 className="text-shadow text-lg max-w-1/2 md:max-w-none md:text-2xl text-gray-300 ">Buy Talman guitars, basses and parts!</h3>
      </Link>
      <div className="w-full h-full absolute left-0 top-0 z-10 bg-gradient-to-r from-gray-900  via-transparent"></div>
      <div className="w-full h-full absolute left-0 top-0 z-0 flex"><StaticImage className="pixelate" src="../assets/images/store/store-banner.jpg" alt="Talman Prestige guitar in a case"  quality={90} placeholder="blurred" transformOptions={{ cropFocus: "center"}}  /></div>
    </div>

    <InstaGrid />

  </Layout>
)

export default IndexPage