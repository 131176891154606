import 'keen-slider/keen-slider.min.css'

import React, { useEffect, useRef, useState } from 'react'

import { useKeenSlider } from 'keen-slider/react'

const Slider = ({className, classNameSlider, children, limitAspect, forcePause}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [timer_timestamp, setTimerTimestamp] = useState(Date.now()); // NOTE: update timestamp to trigger restarting timer
  const [pause, setPause] = useState(false);
  const timer = useRef();
  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    slideChanged (s) {
      setCurrentSlide(s.details().relativeSlide)
    },
    loop: true,
    mode: 'free-snap',
    duration: 2000,
    dragStart: () => {
      if (!forcePause) { setPause(true) }
    },
    dragEnd: () => {
      if (!forcePause) { setPause(false) }
    }
  });

  useEffect(() => {
    sliderRef.current.addEventListener("mouseover", () => {
      if (!forcePause) { setPause(true) }
    })
    sliderRef.current.addEventListener("mouseout", () => {
      if (!forcePause) { setPause(false) }
    })
  }, [sliderRef, forcePause])

  useEffect(() => {
    timer.current = setInterval(() => {
      if (!forcePause && !pause && slider) {
        slider.next()
      }
    }, 5000)
    return () => {
      clearInterval(timer.current)
    }
  }, [pause, forcePause, slider, timer_timestamp])


  const renderSliderHtml = () => {
    if (limitAspect === '4/5') {
      return <div className="aspect-w-4 aspect-h-5 overflow-hidden">
        <div className="absolute">
          <div className={`keen-slider w-full h-full ${classNameSlider}`} ref={sliderRef}>
            {React.Children.map(children, (child) => {
              return <div className="keen-slider__slide flex flex-col justify-center">{child}</div>
            })}
          </div>
        </div>
      </div>;
    }

    return <div className={`keen-slider ${classNameSlider}`} ref={sliderRef}>
      {React.Children.map(children, (child) => {
        return <div className="keen-slider__slide">{child}</div>
      })}
    </div>  
  };

  return (<div className={`${className} slider-wrap`}>

    {renderSliderHtml()}

    {slider && (
        <div className="dots">
          {[...Array(slider.details().size).keys()].map((idx) => {
            return (
              <button
                aria-label={`slide ${idx + 1} navigation`}
                key={idx}
                onClick={() => {
                  slider.moveToSlideRelative(idx);
                  setTimerTimestamp(Date.now());
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              />
            )
          })}
        </div>
      )}
  </div>)
}

export default Slider